
@media only screen and (min-width:1000px) {

    .budget_tb{
        overflow: auto;
        max-height:43vh;
    }
    
  }

  @media only screen and (min-width:1400px) {

    .budget_tb{
        overflow: auto;
        max-height:47vh;
    }
    
  }  

  @media only screen and (min-width:1900px) {

    .budget_tb{
        overflow: auto;
        max-height:64vh;
    }
    
  }  