.button-new {
    display: flex;
    align-items: center;
    pointer-events: auto;
    justify-content: flex-end;
    margin: 5px;
    .button-align{
        margin-left: 10px;
    }
}
