@import "../../../../styles/sass/NewThemeVariables.scss";

.cnc-view-runs-sidenav-container {
  .view-runs-filters {
    // padding: 16px;

    .view-runs-filters-search-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid $inputEleBorderColor;
      border-radius: 3px;

      .input-label {
        width: 70%;
        border-radius: 3px;
        border: 0px;
        height: $inputEleHeight;
        box-sizing: border-box;
        color: $primaryColor;
        font-size: $primaryFontSizeXS;
        padding-left: 6px;

        &::placeholder {
          color: $primaryColor;
          opacity: 0.5;
        }
      }

      .cancel-icon {
        width: 15%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $primaryColor;
        font-size: 15px;
      }

      .search-icon {
        width: 15%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $fifthColor;
        font-size: 18px;
        height: 26px;
        border-left: 1px solid $inputEleBorderColor;
      }
    }

    .view-runs-filters-date-container {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      justify-content: space-between;
      margin-top: 5px;
      float: right;

      .view-runs-filters-date {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .date-title {
          font-size: 10px;
          color: $primaryColor;
        }

        .date-icon {
          font-size: 16px;
          color: $primaryColor;
        }
      }
    }
  }

  .models-menu {
    display: flex;

    .models-item {
      padding-right: 30px;
      text-align: center;
      cursor: pointer;

      .models-item-div {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 25px;

        p {
          font-size: 0.875rem;
        }

        .selected-model-icon {
          margin-left: 5px;
          color: $secondaryColor;
          font-size: $primaryFontSizeXXS;
        }

        &.active {
          background-color: white;
          color: $secondaryColor;
          border-bottom: 3px solid $secondaryColor;
          font-weight: bolder;
        }
      }
    }
  }

  .cnc-no-runs {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 8%;
    color: $primaryColor;
    font-size: $primaryFontSizeS;

    .cnc-img {
      height: 20vh;
      width: 100%;
    }
  }
}

.runs-icon {
  margin-right: 7px;
  width: 20px;
  height: 20px;

  &.Running {
    width: 30px;
    height: 30px;
    background-image: url("../../../../assets/images/running.svg");
    background-repeat: no-repeat;
  }

  &.Failed {
    background-image: url("../../../../assets/images/run_fail.svg");
    background-repeat: no-repeat;
  }

  &.Unread {
    background-image: url("../../../../assets/images/run_fail.svg");
    background-repeat: no-repeat;
  }

  &.Completed {
    background-image: url("../../../../assets/images/run_sucess.svg");
    background-repeat: no-repeat;
  }

  &.Queued {
    background-image: url("../../../../assets/images/run_normal.svg");
    background-repeat: no-repeat;
  }
}

.hide-icons {
  cursor: default;
}

.hide-icon {
  opacity: 0.25;
  cursor: default !important;
}