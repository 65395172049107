@import "../../../../styles/sass/NewThemeVariables.scss";

.dss-filter-new {
    width: 100%;
    display: flex;
    height: 42px;
    justify-content: space-between;
    margin-bottom: 25px;
    
    .input-search {
        width: 52%;
        border-radius: 4px;
        background-color: #f7f7f7;
        display: flex;
        align-items: center;
        justify-content: space-around;
        height: 28px;
        font-size: 14px;
        padding: 5px 10px;
        .search-icon {
            color: #46596a;
            font-size: 15px;
        }
        .input-label {
            width: 100%;
            float: left;
            height: 100%;
            border: 0;
            color: #46596a;
            padding-left: 10px;
            font-family: "Hind Siliguri";
            background-color: #f7f7f7;
        }
        .cancel-icon {
            color: #000000;
            font-size: 15px;
            opacity: 1;
        }
    }
    .right-filter-div {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        .filter-btn {
            justify-content: flex-end;
            align-items: center;
            background: #46596a!important;
            border-radius: 5px;
            text-align: center;
            height: 32px;
            width: 100px;
        }
    }
    .myconfig-switch-icon {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 40;
        border-radius: 5px;
        background: rgba(70,89,106,.07);
        height: 32px;
        width: 100px;
        padding-right: 3px;
        margin-left: 10px;
    }
}
.dss-apps-row {
    margin: 5px 0 0;
}

.table_scenario{
    height: 100% !important;
}
.table-heading{
    background-color: #e5e5e5;
    color: #212529;
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; */
    font-size: 14px;
    font-style: normal;
    font-family: $fontFamily;
    width: 100%;
    border-radius: 20% !important;
    // height: 100% !important;
    th{
        color: rgba(70, 89, 106, 1);
        padding: 12px;
        font-size: 13px;
        font-family: Hind Siliguri;
        font-weight: 600;
        line-height: 16px;
        border-bottom: none;
        text-transform: capitalize;
        background-color: #E6E8ED;
        
    }
    th:first-child {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
    }
    th:last-child {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
    }
}
.table_body_scenario{
    font-size:12px;
    font-family: $fontFamily;
    padding-top: 10px;
    width: 100%;
    tbody {
        padding: 10px;
        border-radius: 4px;
        
    tr{
        padding: 9px !important;
        font-size: 13px !important;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
    }
    tr:first-child {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
    }
    }
    // height: 100% !important;
}
.no-scenario {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 8%;
    color: #46596a;
    font-size: 13px;
    img {
        height: 20vh;
        width: 100%;
    }
}

.MuiMenuItem-root{
    width: auto !important;
    overflow: hidden !important;
    font-size: 13px !important;
    box-sizing: border-box !important;
    min-height: 35px !important;
    font-family: 'Hind Siliguri'  !important;
    font-weight: 400 !important;
    line-height: 15px !important;
    padding-top: 6px !important;
    white-space: nowrap !important;
    letter-spacing: 0.00938em !important;
    padding-bottom: 6px !important;
}

// .jss1050:nth-of-type(odd) {
//     background-color: white;
//     thead {
//         border-bottom: none;
//     }
// }

// .Table-root {
//     width: 100%;
//     display: table;
//     border-spacing: 0;
//     border-collapse: collapse;
// }

// .TableHeader-root {
//     color: inherit;
//     display: table-row;
//     outline: 0;
//     vertical-align: middle;
// }