.dss-share-cards {
    margin-top: 12px;
    max-height: 40vh;
    min-height: 220px;
    overflow-y: auto;
    color: rgb(70, 89, 106);
}

.single-share-card {
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .share-card-left {
        .share-card-email {
            margin-left: 10px;
            display: flex;
            flex-direction: column;
            font-size: 13px;
            .primary-name {
                color: rgb(70, 89, 106);
                font-size: 13px;
            }
            .secondary-name {
                color: rgb(70, 89, 106);
                opacity: 0.7;
                font-size: 11px;
            }
        }
    }
    .share-card-action{
        justify-content: end;
        width:220px
    }
}