.cnc-dropzone{
    // width: 100%;
    border: dashed;
    cursor: pointer;
    position: relative;
    box-sizing: border-box;
    min-height: 250px;
    border-color: #C8C8C8;
    background-color: #F0F0F0;

    padding: 20px;
    display: flex;
    flex-direction: column;
    width: 80%;
    align-items: center;
}

.cnc-upload-file-info{
    display: flex;
    width: 100%;
    justify-content: space-evenly;
}
.dropbox-img{
    height: 140px;
    width: 140px;
}