.mandatory-field {
  display: inline !important;
  color: red !important;
  font-size: 16 !important;
}

.admin-util-header {
  background-color: #e5e5e5;
  padding: 5px;
  margin: 4px;
  font-size: small;

  .admin-util-header-item {
    font-size: small;
    height: 25px;
  }

  .admin-util-body-item {
    margin-bottom: 15px;
  }
}

.manage-header {
  background-color: #ffffff;
  padding: 5px;
  margin: 4px;
  font-size: small;

  .manage-header-item {
    font-size: small;
    height: 25px;
  }

  .manage-body-item {
    margin-bottom: 15px;
  }
}

.grid-container-style {
  padding: 2px 15px 0px 15px;
}

.admin-share-cards {
  margin-top: 12px;
  max-height: 50vh;
  min-height: 220px;
  overflow-y: auto;
  color: rgb(70, 89, 106);
}

.admin-single-share-card {
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 90vh;

  .display-left {
    .display-label {
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      font-size: 13px;

      .primary-name {
        color: rgb(70, 89, 106);
        font-size: 13px;
      }

      .secondary-name {
        color: rgb(70, 89, 106);
        opacity: 0.7;
        font-size: 11px;
      }
    }
  }

  .share-card-action {
    justify-content: end;
    width: 350px;
  }
}

.manage-card {
  padding: 10px 15px;
  padding-left: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .display-left {
    .display-label {
      display: flex;
      flex-direction: column;
      font-size: 13px;
      .primary-name {
        color: rgb(70, 89, 106);
        font-size: 13px;
      }
      .secondary-name {
        color: rgb(70, 89, 106);
        opacity: 0.7;
        font-size: 11px;
      }
    }
  }

  .share-card-action {
    justify-content: flex-end;
    margin: 5px;
  }
}

.jss546 {
  display: flex;
  align-items: center;
  pointer-events: auto;
  justify-content: flex-end;
}
.jss2372 {
  color: white;
  font-size: 11px;
  // width: 100%;
  font-family: Hind Siliguri;
  font-weight: bold;
  text-transform: capitalize;
  background-color: #46596a;
  // margin-left : 10px !important
}
.Commodity-dropdown {
  .CommoditySlect {
    display: flex;
    align-items: center;
    .css-yk16xz-control {
      border: none !important;
    }
  }
  .css-g1d714-ValueContainer {
    display: none !important;
  }
  .css-1hwfws3 {
    display: none !important;
  }
  .MuiChip-root {
    font-family: Hind Siliguri !important;
    border-radius: 2px !important;
  }
  .MuiChip-deleteIcon {
    padding: 2px !important;
  }
  .MuiChip-deleteIcon:hover {
    background-color: #ffbdad;
    color: #de350b;
  }
  .material-icons-outlined {
    font-size: 20px !important;
  }

  .css-26l3qy-menu {
    width: max-content !important;
    margin-left: -194%;
  }
  .low {
    .css-26l3qy-menu {
      width: max-content !important;
      margin-left: -473%;
    }
  }
}
