@import "../../../styles/sass/NewThemeVariables.scss";
.table-headers{
    background-color: #e5e5e5;
    color: #212529;
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; */
    font-size: 14px;
    font-style: normal;
    font-family: $fontFamily;
    width: 100%;
    border-radius: 20% !important;
    // height: 100% !important;
    th{
        color: rgba(70, 89, 106, 1);
        // padding: 12px;
        padding-top:12px;
        padding-left: 6px;
        font-size: 13px;
        font-family: Hind Siliguri;
        font-weight: 600;
        line-height: 16px;
        border-bottom: none;
        text-transform: capitalize;
        background-color: #E6E8ED;
        
    }
    th:first-child {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
    }
    th:last-child {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
    }
}
.table-body{
    font-size:12px;
    font-family: $fontFamily;
    padding-top: 10px;
    width: 100%;
    max-height: 600px;
    overflow-y: auto;
    tbody {
        padding: 10px;
        border-radius: 4px;
        
    tr{
        padding: 9px !important;
        font-size: 13px !important;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
    }
    tr:first-child {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
    }
    }
    // height: 100% !important;
}
.no-commodity {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 8%;
    color: #46596a;
    font-size: 13px;
    .cnc-img {
        height: 20vh;
        width: 100%;
    }
}


.table-heading{
    background-color: #e5e5e5;
    color: #212529;
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; */
    font-size: 14px;
    font-style: normal;
    font-family: $fontFamily;
    width: 100%;
    border-radius: 20% !important;
    // height: 100% !important;
    th{
        color: rgba(70, 89, 106, 1);
        padding: 12px;
        font-size: 13px;
        font-family: Hind Siliguri;
        font-weight: 600;
        line-height: 16px;
        border-bottom: none;
        text-transform: capitalize;
        background-color: #E6E8ED;
        
    }
    th:first-child {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
    }
    th:last-child {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
    }
}
.table_body_scenario{
    font-size:12px;
    font-family: $fontFamily;
    padding-top: 10px;
    width: 100%;
    tbody {
        padding: 10px;
        border-radius: 4px;
        
    tr{
        padding: 9px !important;
        font-size: 13px !important;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
    }
    tr:first-child {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
    }
    }
    // height: 100% !important;
}

.table_bodyscenario{
    font-size:12px;
    font-family: $fontFamily;
    padding-top: 10px;
    width: 100%;
    max-height: 600px;
    overflow-y: auto;
    tbody {
        padding: 10px;
        border-radius: 4px;
        
    tr{
        padding: 9px !important;
        font-size: 13px !important;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
    }
    tr:first-child {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
    }
    }
    // height: 100% !important;
}

.tableheader{
    background-color: #e5e5e5;
    color: #212529;
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; */
    font-size: 14px;
    font-style: normal;
    font-family: $fontFamily;
    width: 100%;
    border-radius: 20% !important;
    // height: 100% !important;
    th{
        color: rgba(70, 89, 106, 1);
        padding: 12px;
        font-size: 13px;
        font-family: Hind Siliguri;
        font-weight: 600;
        line-height: 16px;
        border-bottom: none;
        text-transform: capitalize;
        background-color: #E6E8ED;
        
    }
    th:first-child {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
    }
    th:last-child {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
    }
}
// .jss1050:nth-of-type(odd) {
//     background-color: white;
//     thead {
//         border-bottom: none;
//     }
// }

// .Table-root {
//     width: 100%;
//     display: table;
//     border-spacing: 0;
//     border-collapse: collapse;
// }

// .TableHeader-root {
//     color: inherit;
//     display: table-row;
//     outline: 0;
//     vertical-align: middle;
// }