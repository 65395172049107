@import "../../../../styles/sass/NewThemeVariables.scss";
.cnc-models-menu {
    display: flex;

    .models-item {
      padding-right: 30px;
      text-align: center;
      cursor: pointer;

      .models-item-div {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 25px;

        p {
          font-size: 0.875rem;
        }

        .selected-model-icon {
          margin-left: 5px;
          color: $secondaryColor;
          font-size: $primaryFontSizeXXS;
        }
        &.active {
          background-color: white;
          color: $secondaryColor;
          border-bottom: 3px solid $secondaryColor;
          font-weight: bolder;
        }
      }
    }
  }

  .cnc-button-footer{
    display: flex;
    position: absolute;
    bottom: 25px;
    right: 30px;
}

  .cnc-toggle-icon {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 5px;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    text-align: right;
    text-transform: capitalize;
    color: #46596a;
    position: relative;
    right: 15px;
    margin-top: -27px;
}
.cnc-model-header{
    background-color:#e5e5e5 ;
    padding: 5px;
    margin:4px;
    font-size: small;
    
}
.cnc-model-header-item{
    font-size: small;
}
 
.cnc-table-headers{
    background-color: #e5e5e5;
    color: #212529;
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; */
    font-size: 14px;
    font-style: normal;
    font-family: $fontFamily;
    width: 100%;
    border-radius: 20% !important;
    // height: 100% !important;
    th{
        color: rgba(70, 89, 106, 1);
        // padding: 12px;
        padding-top:12px;
        padding-left: 6px;
        font-size: 13px;
        font-family: Hind Siliguri;
        font-weight: 600;
        line-height: 16px;
        border-bottom: none;
        text-transform: capitalize;
        background-color: #E6E8ED;
        
    }
    th:first-child {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
    }
    th:last-child {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
    }
}
.cnc-table-body{
    font-size:12px;
    font-family: $fontFamily;
    padding-top: 10px;
    width: 100%;
    max-height: 600px;
    overflow-y: auto;
    tbody {
        padding: 10px;
        border-radius: 4px;
        
    tr{
        padding: 9px !important;
        font-size: 13px !important;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
    }
    tr:first-child {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
    }
    }
    // height: 100% !important;
}
.importance-type {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    color: #46596a;
    font-size: 13px;
    img {
        height: 50vh;
        width: 50%;
    }
}
.form-control-cnc {
    display: block;
    width: 90%;
    padding: 0.55rem 0.55rem;
    font-size: 12px !important;
    font-weight: 200;
    //  line-height: 1.25; 
    color: #212529;
    background-color: #f1f2f7;
    border : solid 2px rgb(41, 41, 41) !important;
    background-clip: padding-box;
    border: 1px solid #fdfeff;
    font-family: inherit;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    // min-height: 25px;
    // position: fixed;
}
.table-input {
    display: block;
    width: 75%;
    padding: 0.55rem 0.55rem;
    font-size: 12px !important;
    font-weight: 200;
    //  line-height: 1.25; 
    color: #212529;
    background-color: #f1f2f7;
    border : solid 2px rgb(41, 41, 41) !important;
    background-clip: padding-box;
    border: 1px solid #fdfeff;
    font-family: inherit;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    // min-height: 25px;
    // position: fixed;
}
input:disabled{
    cursor: default !important;
    background-color: rgba(239, 239, 239, 0.5) !important;
    color: rgb(84, 84, 84) !important;
    border-color: rgba(118, 118, 118, 0.3) !important;
}
.submit-button {
    display: flex;
    align-items: center;
    pointer-events: auto;
    justify-content: flex-end;
    /* margin: -7px; */
}
.cnc-card-header{
    font-size:16px;
    font-weight: bold;
    font-family: $fontFamily;
}
.cnc-card-content-title{
    text-align: center;
}
.cnc-card-content{
    padding: 0px !important;
}

.cnc-scenario-flag{
    margin-top: -8px;
}

.cnc-save-btn-pos{
     display: flex;
     justify-content: end;
}

.cnc-no-commodity{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 8%;
    color: #46596a;
    font-size: 13px;
    .cnc-img {
        height: 20vh;
        width: 100%;
        // display: flex;
        // justify-content: center;
        // align-items: center;
    }
}
.form-control {
    display: block;
    width: 90%;
    padding: 0.55rem 0.55rem;
    font-size: 12px !important;
    font-weight: 200;
    //  line-height: 1.25; 
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #acd3fb;
    font-family: inherit;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    // min-height: 25px;
    // position: fixed;
}
input:disabled{
    cursor: default !important;
    background-color: rgba(239, 239, 239, 0.5) !important;
    color: rgb(84, 84, 84) !important;
    border-color: rgba(118, 118, 118, 0.3) !important;
}
.MuiTypography-body1 {
    font-size: 12px !important;
    font-family: $fontFamily;
    font-weight: 400;
    color: #212529 !important;
    line-height: 1.5;
    letter-spacing: 0.00938em;
}

.MuiRadio-colorSecondary.Mui-checked {
    color: #4eafb3 !important;
}

.model-view{
    // overflow-x:hidden; height:250px;
//     border-style: rounded;
  border: 2px solid #E75480;
  border-radius: 5px;
}
.mb-tabs-container {
    display: flex;
    margin-top: 10px;
    align-items: center;
    font-weight: 500;
    justify-content: space-between;
    
    // padding: 8px;
    // background-color: #f7f7f7;
    .mb-tab-selection-div {
        padding: 6px 12px;
        display: flex;
        align-items: center;
        margin: 2px;
        height:10px;        
        .disabled {
            pointer-events: none;
            cursor: default;
          }
        .mb-tab-selection-title {
            margin-top:10px;
            font-weight: 600;
            cursor: pointer;
            font-size: 12px;
            padding: 6px 12px;
            // padding-left: 3px;
        }
        .mb-active{
            text-transform: capitalize;
            color: black;
            background:#E75480;
            border-radius: 2px;
            font-weight: bolder;
            // font-family: "Hind Siliguri"!important;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            // width:70px;   
             padding: 6px 12px;
        }
        a {
            color: grey;
            padding: 6px 12px;
            font-size: 12px;
            text-decoration: none;
            padding: 6px 12px;
            .disabled {
                pointer-events: none;
                cursor: default;
              }
        }

        .mb-status{
            width: 225px;
        }
    }
}
.sel {
    display: flex;
}

.modelrun-failed {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0;
    color: #46596a;
    font-size: 13px;
}

// .cnc-img {
//     height: 20vh;
//     width: 100%;
//     // display: flex;
//     // justify-content: center;
//     // align-items: center;
// }

// .jss1050:nth-of-type(odd) {
//     background-color: white;
//     thead {
//         border-bottom: none;
//     }
// }

// .Table-root {
//     width: 100%;
//     display: table;
//     border-spacing: 0;
//     border-collapse: collapse;
// }

// .TableHeader-root {
//     color: inherit;
//     display: table-row;
//     outline: 0;
//     vertical-align: middle;
// }


@media screen and (min-width:1400px) {
    .cnc-no-img-height{
        max-height: 28vh;
    }
    
  } 

  @media screen and (min-width:1900px) {

    .cnc-no-img-height{
        max-height: 37vh;
    }
}   