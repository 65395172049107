@media only screen and (min-width: 1000px) {
  .execution_tb {
    overflow: auto;
    max-height: 54vh;
  }
}

@media only screen and (min-width: 1400px) {
  .execution_tb {
    overflow: auto;
    max-height: 44vh;
  }
}

@media only screen and (min-width: 1900px) {
  .execution_tb {
    overflow: auto;
    max-height: 68vh;
  }
}
