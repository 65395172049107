
@import "../../../styles/sass/NewThemeVariables.scss";
.treetable-normal{
	.cp_tree-table {
		font-size: 14px;
		color: #040402;
		background: #fff;
		height:max-content;
		border: 1px solid rgba(55, 53, 47, 0.2);
		font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', sans-serif;
		// overflow: auto;
		.cp_tree-table_header {
			color: rgba(55, 53, 47, 0.6);
			background: rgba(242, 241, 238, 0.6);
			border-bottom: 1px solid rgba(55, 53, 47, 0.09);
			height: 56px !important;
			// min-width: 135% !important;
			// overflow: scroll !important;
		}
		.cp_tree-table_viewport {
			overflow: auto;
			max-height: 30vh;
			height: unset !important;
			// min-width: 135% !important;
		}
		.cp_tree-table_header-cell {
			>.t-right {
				width: 100%;
				text-align: right;
			}
			display: flex;
			align-items: center;
			padding: 0 10px;
			// padding: 9px 9px;
			// min-width: 120px;
		}
		.cp_tree-table_cell {
			display: flex;
			align-items: center;
			// padding: 0 10px;
			padding: 9px 9px;
			// min-width: 120px;
			>input {
				font-size: 14px;
				outline: none;
				width: 100%;
				min-width: 60%;
				height: 100%;
				padding: 5px 8px;
				box-sizing: border-box;
				box-shadow: inset 0 0 0 1px rgba(55, 53, 47, 0.16);
				border: 1px solid transparent;
				&:hover {
					box-shadow: inset 0 0 0 1px #0f55eb;
				}
				&:focus {
					background: #edf6fc;
					box-shadow: inset 0 0 0 1px #0f55eb;
				}
			}
			.toggle-button {
				position: relative;
				display: inline-block;
				border: none;
				height: 14px;
				vertical-align: middle;
				padding: 0 5px 0 0;
				margin: 0;
				cursor: pointer;
				background-color: transparent;
				outline: none;
				&::after {
					content: '';
					display: inline-block;
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 5px 5px 0 5px;
					border-color: #040402 transparent transparent transparent;
					padding: 0;
					vertical-align: middle;
				}
			}
			.toggle-button+span {
				vertical-align: middle;
			}
			>.without-children {
				>span {
					padding-left: 15px;
				}
			}
			>.with-children {
				>span {
					font-weight: 600;
				}
			}
			>.employees-cell {
				width: 100%;
				text-align: right;
			}
			>.expenses-cell {
				font-family: 'SF Mono', 'Segoe UI Mono', 'Roboto Mono', Menlo, Courier, monospace;
				font-weight: 400;
				width: 100%;
				text-align: right;
				padding-right: 10px;
			}
		}
	
		/*Demand Tree Difference enhacement adjustment changes*/
		.cp_tree-table_row{
		overflow: unset !important;
		}
	
		.cp_tree-table_mover{
		overflow: auto !important;
		}
	
	}
}


.collapsed{

    left:0;
    transform:rotate(270deg)
}


.triangle_indicator_profit{
	font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 1, 'opsz' 48;
    color:green;
	transform: rotate(-90deg);
}

.triangle_indicator_loss{
	font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 1, 'opsz' 48;
    color:red;
	transform: rotate(90deg);
}

.demand_difference{
	max-width: 100%;
	overflow-x: auto;
}

.treeCell{
	display: flex;
}



//for Demand tree expansion width while diff flag enabled

.difference-div{
	font-size: 10px;
	margin-left: -3px;
}

.difference-div-normal{
	font-size: 12px;
	margin-left: -3px;
}
.treetable-Demand{
	.cp_tree-table {
		font-size: 14px;
		color: #040402;
		background: #fff;
		height: max-content;
		border: 1px solid rgba(55, 53, 47, 0.2);
		font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', sans-serif;
		overflow: auto;
		.cp_tree-table_header {
			color: rgba(55, 53, 47, 0.6);
			background: rgba(242, 241, 238, 0.6);
			border-bottom: 1px solid rgba(55, 53, 47, 0.09);
			height: 56px !important;
			min-width: 140% !important;
			// overflow: scroll !important;
		}
		.cp_tree-table_viewport {
			overflow: auto;
			max-height: 30vh;
			height: unset !important;
			min-width: 140% !important;
		}
		.cp_tree-table_header-cell {
			>.t-right {
				width: 100%;
				text-align: right;
			}
			display: flex;
			align-items: center;
			padding: 0 10px;
			// padding: 9px 9px;
			// min-width: 120px;
		}
		.cp_tree-table_cell {
			display: flex;
			align-items: center;
			// padding: 0 10px;
			padding: 9px 9px;
			// min-width: 120px;
			>input {
				font-size: 14px;
				outline: none;
				width: 80%;
				min-width: 67%;
				height: 100%;
				padding: 5px 8px;
				box-sizing: border-box;
				box-shadow: inset 0 0 0 1px rgba(55, 53, 47, 0.16);
				border: 1px solid transparent;
				&:hover {
					box-shadow: inset 0 0 0 1px #0f55eb;
				}
				&:focus {
					background: #edf6fc;
					box-shadow: inset 0 0 0 1px #0f55eb;
				}
			}
			.toggle-button {
				position: relative;
				display: inline-block;
				border: none;
				height: 14px;
				vertical-align: middle;
				padding: 0 5px 0 0;
				margin: 0;
				cursor: pointer;
				background-color: transparent;
				outline: none;
				&::after {
					content: '';
					display: inline-block;
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 5px 5px 0 5px;
					border-color: #040402 transparent transparent transparent;
					padding: 0;
					vertical-align: middle;
				}
			}
			.toggle-button+span {
				vertical-align: middle;
			}
			>.without-children {
				>span {
					padding-left: 15px;
				}
			}
			>.with-children {
				>span {
					font-weight: 600;
				}
			}
			>.employees-cell {
				width: 100%;
				text-align: right;
			}
			>.expenses-cell {
				font-family: 'SF Mono', 'Segoe UI Mono', 'Roboto Mono', Menlo, Courier, monospace;
				font-weight: 400;
				width: 100%;
				text-align: right;
				padding-right: 10px;
			}
		}
	
		/*Demand Tree Difference enhacement adjustment changes*/
		.cp_tree-table_row{
		overflow: unset !important;
		}
	
		.cp_tree-table_mover{
		overflow: auto !important;
		}
	
	}
}





